@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInZoom {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.transition-transform {
  transition: transform 0.7s ease;
}

.transition-opacity {
  transition: opacity 1s ease;
}

.-translate-y-2 {
  transform: translateY(-0.5rem); /* Ajustez la valeur selon votre besoin */
}
